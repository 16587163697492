<template>
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_617_3778)">
      <path
        d="M24.9449 0.00203409C24.6316 0.0214062 24.3265 0.307629 24.3774 0.620004C24.3874 0.683448 24.4114 0.746407 24.4038 0.810335C24.3783 1.02246 24.0766 1.04522 23.9249 1.19487C23.8367 1.28205 23.8045 1.41329 23.8021 1.53776C23.7968 1.82446 23.9389 2.1102 24.1696 2.27729C24.5678 2.56545 25.1153 2.47295 25.5888 2.34412C25.7716 2.29424 25.9875 2.20271 26.0091 2.01286C26.0249 1.87193 25.9227 1.71356 26.0024 1.59684C26.0412 1.53969 26.1122 1.515 26.1616 1.46705C26.295 1.33629 26.2173 1.11012 26.1228 0.948846C25.8613 0.503287 25.537 -0.0347729 24.9449 0.00203409Z"
        fill="#F7B658"
      />
      <path
        d="M31.3805 6.24504C31.12 6.26247 30.8595 6.28814 30.6004 6.32253C30.481 6.33802 30.3471 6.36466 30.2809 6.46636C30.2382 6.53223 30.2344 6.61505 30.2344 6.6935C30.2344 6.91095 30.2526 7.12841 30.2886 7.34295C30.3073 7.45434 30.3308 7.57106 30.2967 7.67906C30.2588 7.79868 30.1518 7.89796 30.1566 8.0234C30.1619 8.16384 30.3006 8.25538 30.4263 8.31543C30.6091 8.40261 30.799 8.47477 30.9938 8.53095C31.2064 8.59245 31.4304 8.63556 31.6482 8.59633C31.8661 8.5571 32.0776 8.42295 32.1602 8.21567C32.2427 8.00838 32.1578 7.7333 31.9524 7.65048C31.9097 7.63305 31.8579 7.61997 31.8387 7.57735C31.6626 7.19476 32.2422 6.17288 31.3801 6.24455L31.3805 6.24504Z"
        fill="#F7B658"
      />
      <path
        d="M31.5877 10.7337C31.1584 10.7119 30.7294 10.6896 30.3 10.6678C30.195 10.6625 30.0875 10.6577 29.9877 10.6916C29.8601 10.7352 29.7627 10.8373 29.6605 10.926C29.3645 11.1841 28.9927 11.3526 28.6045 11.404C28.4951 11.4185 28.3694 11.4321 28.3061 11.5231C28.2749 11.5677 28.2648 11.6229 28.2567 11.6766C28.1804 12.1677 28.1828 12.6709 28.2634 13.161C28.2749 13.2313 28.2888 13.3039 28.3287 13.3625C28.3843 13.4458 28.4812 13.4889 28.5753 13.5218C29.0862 13.7005 29.7046 13.7112 30.0544 14.1277C30.2074 14.3098 30.2928 14.5568 30.492 14.6851C30.8115 14.891 31.2265 14.69 31.5498 14.4909C31.6712 14.4163 31.7984 14.3355 31.8636 14.2081C31.9802 13.98 31.8574 13.6952 31.9308 13.4492C31.974 13.3039 32.0824 13.1857 32.1285 13.0409C32.2503 12.6617 31.9159 12.3905 31.8046 12.0621C31.665 11.65 31.6794 11.1609 31.5873 10.7327L31.5877 10.7337Z"
        fill="#F7B658"
      />
      <path
        d="M24.2988 6.01947C25.029 5.82962 25.8729 5.67658 26.4813 6.1265C27.0762 6.56624 27.1942 7.39198 27.4523 8.08938C27.4864 8.18091 27.532 8.28068 27.6217 8.317C27.698 8.34751 27.7843 8.32378 27.8654 8.3354C28.0329 8.35913 28.1461 8.53493 28.1586 8.70541C28.171 8.87588 28.1077 9.04103 28.0453 9.19988C27.9475 9.4493 27.8414 9.71034 27.6356 9.88033C27.3348 10.1283 26.8843 10.1288 26.5912 10.3864C26.3321 10.6145 26.24 11.0083 25.9454 11.1865C25.6561 11.3613 25.2871 11.263 24.9657 11.1599C24.8793 11.1322 24.7887 11.1017 24.7282 11.033C24.5809 10.8644 24.7028 10.6087 24.7594 10.3913C24.8553 10.0256 24.721 9.60912 24.4307 9.37036C24.0153 9.02844 23.3594 9.05217 23.0169 8.63615C22.5592 8.08017 23.1128 7.6782 23.25 7.1663C23.4352 6.47471 23.5388 6.21706 24.2988 6.01947Z"
        fill="#F7B658"
      />
      <path
        d="M25.5033 2.38095C25.6861 2.33107 25.902 2.23954 25.9236 2.04969C25.9389 1.91263 25.8435 1.76008 25.9106 1.64433C25.4597 1.75959 24.9559 1.82352 24.5826 1.55328C24.4008 1.42204 24.2746 1.21718 24.2314 0.99585C24.1244 1.08448 23.9455 1.1271 23.8395 1.2317C23.7512 1.31888 23.719 1.45013 23.7166 1.57459C23.7114 1.8613 23.8534 2.14704 24.0841 2.31412C24.4824 2.60228 25.0298 2.50978 25.5033 2.38095Z"
        fill="#E2A359"
      />
      <path
        d="M24.338 0.470856C24.4891 0.321207 24.7909 0.298929 24.8168 0.0863198C24.8187 0.072275 24.8187 0.0577459 24.8177 0.0437012C24.563 0.0790553 24.325 0.283915 24.2914 0.529457C24.3049 0.508632 24.3202 0.488291 24.338 0.470372L24.338 0.470856Z"
        fill="#E2A359"
      />
      <path
        d="M31.564 8.63376C31.7818 8.59501 31.9934 8.46038 32.0759 8.25358C32.1239 8.13299 32.1148 7.98915 32.0586 7.87389C31.8423 7.91166 31.6192 7.86904 31.4081 7.80802C31.2138 7.75184 31.0238 7.67968 30.8405 7.59251C30.7148 7.53245 30.5757 7.44092 30.5709 7.30047C30.5665 7.17504 30.6731 7.07576 30.711 6.95613C30.7455 6.84813 30.722 6.7319 30.7028 6.62003C30.687 6.52752 30.6754 6.43454 30.6668 6.34155C30.6164 6.34736 30.5661 6.35366 30.5162 6.35996C30.3967 6.37545 30.2628 6.40209 30.1966 6.50379C30.1539 6.56966 30.1501 6.65247 30.1501 6.73093C30.1501 6.94838 30.1683 7.16584 30.2043 7.38038C30.223 7.49177 30.2465 7.60849 30.2125 7.71649C30.1746 7.83611 30.0676 7.93539 30.0724 8.06083C30.0777 8.20079 30.2163 8.29281 30.342 8.35286C30.5248 8.44004 30.7148 8.5122 30.9096 8.56838C31.1221 8.62988 31.3462 8.67299 31.564 8.63376Z"
        fill="#E2A359"
      />
      <path
        d="M31.4657 14.5287C31.587 14.4541 31.7142 14.3732 31.7794 14.2459C31.8413 14.1248 31.8356 13.9872 31.8279 13.8483C31.53 14.0144 31.1826 14.1403 30.9062 13.9625C30.7067 13.8342 30.6213 13.5872 30.4687 13.4051C30.1189 12.9886 29.5005 12.978 28.9896 12.7993C28.8955 12.7663 28.7986 12.7227 28.743 12.6399C28.7031 12.5808 28.6892 12.5087 28.6777 12.4384C28.6225 12.1038 28.6053 11.7633 28.623 11.4248C28.589 11.4311 28.5549 11.4379 28.5203 11.4422C28.411 11.4568 28.2853 11.4703 28.2219 11.5614C28.1907 11.6059 28.1807 11.6611 28.1725 11.7149C28.0962 12.206 28.0986 12.7092 28.1792 13.1993C28.1907 13.2695 28.2047 13.3422 28.2445 13.4008C28.3001 13.4841 28.397 13.5272 28.4911 13.5601C29.002 13.7388 29.6205 13.7495 29.9702 14.166C30.1233 14.3481 30.2087 14.595 30.4078 14.7234C30.7273 14.9292 31.1423 14.7282 31.4657 14.5292L31.4657 14.5287Z"
        fill="#E2A359"
      />
      <path
        d="M25.8602 11.2237C26.1547 11.0455 26.2469 10.6518 26.5059 10.4237C26.7991 10.1655 27.2491 10.1655 27.5504 9.91756C27.7562 9.74757 27.8622 9.48701 27.9601 9.23711C27.963 9.22937 27.9659 9.22162 27.9692 9.21387C27.6723 9.40614 27.2726 9.42648 27.0044 9.66282C26.7453 9.89092 26.6532 10.2847 26.3586 10.4629C26.0693 10.6377 25.7004 10.5394 25.379 10.4362C25.2926 10.4086 25.2019 10.3781 25.1419 10.3094C24.9947 10.1408 25.116 9.88511 25.1731 9.66766C25.2691 9.30201 25.1347 8.88551 24.8445 8.64675C24.429 8.30483 23.7732 8.32856 23.4306 7.91255C22.9729 7.35657 23.5266 6.9546 23.6638 6.44269C23.683 6.37101 23.7012 6.30466 23.7194 6.24219C23.3908 6.42961 23.2996 6.70324 23.1653 7.20353C23.0281 7.71544 22.4744 8.11741 22.9321 8.67339C23.2747 9.0894 23.9305 9.06567 24.346 9.40759C24.6363 9.64635 24.7701 10.0629 24.6746 10.4285C24.6176 10.646 24.4957 10.9021 24.6435 11.0702C24.7034 11.139 24.7941 11.1695 24.8809 11.1971C25.2024 11.3002 25.5713 11.399 25.8606 11.2237L25.8602 11.2237Z"
        fill="#E2A359"
      />
      <path
        d="M22.4027 5.56328C22.5294 6.16866 22.3802 6.80019 22.4478 7.41526C22.5308 8.16835 22.9381 8.85557 23.4601 9.39993C23.5599 9.50405 23.6679 9.60963 23.7139 9.74669C23.771 9.9162 23.7245 10.1007 23.7072 10.2789C23.6141 11.2224 24.3405 12.05 24.4782 12.9876C24.5569 13.5223 24.4393 14.0657 24.4441 14.6062C24.4489 15.1467 24.6216 15.7457 25.0899 16.0082C25.4368 16.2029 25.9065 16.1908 26.1598 16.4988C26.2716 16.6344 26.3196 16.8112 26.3642 16.9817C26.5028 17.5076 26.6415 18.0331 26.7801 18.5591C26.9519 19.2095 27.1755 19.9292 27.7608 20.2512C28.7295 20.7839 30.1313 19.9185 30.9623 20.6508C31.1293 20.798 31.2665 21.0067 31.4838 21.0508C31.8624 21.1278 32.1123 20.6769 32.4347 20.4614C32.7432 20.2551 33.1434 20.2682 33.5118 20.3045C34.0823 20.3607 34.6494 20.4551 35.2078 20.5864C35.4371 20.6401 35.6708 20.7031 35.8646 20.8387C36.2647 21.1201 36.4096 21.663 36.3689 22.1536C36.2494 23.5861 34.8226 24.6032 33.4125 24.8061C32.0025 25.009 30.5857 24.6133 29.1857 24.3484C27.4441 24.0186 25.6095 23.8685 24.0848 22.957C22.1441 21.7961 21.0469 19.5848 20.4995 17.3759C19.952 15.167 19.8508 12.8666 19.3394 10.6485C19.0011 9.18151 18.482 7.73248 18.4854 6.22678C18.4863 5.83837 18.53 5.42865 18.756 5.11385C19.2828 4.38013 20.1396 4.55497 20.8737 4.51283C21.6178 4.47021 22.2012 4.59952 22.4027 5.56328Z"
        fill="#FFD05A"
      />
      <path
        d="M17.9929 39.9907C19.71 39.8963 21.3163 39.1378 22.9969 38.7698C25.8022 38.1557 29.0128 38.5533 31.2572 36.7473C32.3794 35.8441 33.0693 34.5171 33.727 33.2293C35.3583 30.0368 37.037 26.6118 36.6781 23.0377C36.6201 22.4585 36.5083 21.8875 36.3826 21.3189C36.1552 21.5131 35.9364 21.718 35.7378 21.9427C35.4082 22.3156 35.115 22.8924 34.5858 23.0043C34.1392 23.0992 33.645 22.7529 33.2597 22.5781C32.7248 22.335 32.0757 22.41 31.5019 22.4023C30.1844 22.3848 28.9495 22.1708 27.8163 21.4589C26.8423 20.8472 25.902 20.0592 25.2029 19.1361C24.8959 18.7308 24.6536 18.2838 24.4986 17.797C24.3048 17.1883 24.2511 16.5301 24.1556 15.901C23.9709 14.6801 23.8682 13.3608 23.3203 12.2392C22.8516 11.2803 22.1823 10.4419 21.6617 9.51353C21.1359 8.57447 20.7977 7.54339 20.979 6.45661C21.0884 5.80087 21.3585 5.1858 21.5859 4.56057C21.3676 4.47436 21.1493 4.38961 20.9296 4.31406C18.216 3.37742 15.2328 3.81135 12.4477 4.50148C10.2048 5.05746 7.77091 5.96165 6.73412 8.04464C6.18143 9.15466 6.11714 10.4444 5.71269 11.6178C5.40899 12.4993 4.91915 13.3003 4.49455 14.1294C1.51517 19.9483 1.98486 27.408 5.67047 32.7998C6.09795 33.425 6.57724 34.0352 7.21437 34.4396C7.91868 34.8866 8.76164 35.0474 9.51968 35.3932C11.3059 36.2073 12.4885 37.9687 14.1572 39.0061C15.298 39.7151 16.6553 40.0633 17.9929 39.9902L17.9929 39.9907Z"
        fill="#F7B658"
      />
      <path
        d="M22.9974 38.7698C25.0777 38.3146 27.3806 38.4153 29.3472 37.7615C29.2522 37.7586 29.1572 37.7547 29.0627 37.7504C27.4645 37.6724 25.8578 37.3973 24.2736 37.6225C21.6209 37.9998 18.9529 39.7505 16.4595 38.7616C14.6527 38.0453 13.6404 36.1105 12.0615 34.971C11.4071 34.4988 10.6169 34.1225 10.2623 33.3936C9.90826 32.6657 10.0695 31.7189 9.5633 31.0893C8.94247 30.3158 7.70658 30.4694 6.89193 29.9081C6.48412 29.6272 6.21305 29.1869 5.9986 28.738C5.34611 27.3732 5.11533 25.8065 5.3461 24.309C5.63637 22.4241 6.63237 20.6632 6.63861 18.7555C6.64341 17.231 6.04657 15.5446 6.89145 14.2811C7.175 13.8568 7.59336 13.5415 7.89657 13.1313C8.9396 11.7196 8.35571 9.69569 8.84268 8.00451C9.24617 6.60294 10.3827 5.51132 11.6671 4.83959C11.965 4.68364 12.3018 4.56305 12.6526 4.45215C12.5844 4.46861 12.5163 4.48508 12.4486 4.50203C10.2057 5.05801 7.77183 5.9622 6.73505 8.04519C6.18235 9.15521 6.11806 10.4449 5.71361 11.6179C5.40992 12.4993 4.92007 13.3004 4.49547 14.1295C1.51609 19.9484 1.98578 27.4081 5.67139 32.7998C6.09887 33.4251 6.57816 34.0353 7.2153 34.4397C7.9196 34.8867 8.76256 35.0475 9.5206 35.3933C11.3068 36.2074 12.4894 37.9688 14.1581 39.0062C15.299 39.7152 16.6562 40.0634 17.9938 39.9903C19.711 39.8963 21.3172 39.1374 22.9979 38.7698L22.9974 38.7698Z"
        fill="#E2A359"
      />
      <path
        d="M11.4337 4.77808C11.0955 5.02314 10.7807 5.30016 10.502 5.60673C10.8623 5.31082 11.2567 5.05365 11.6659 4.83959C11.9639 4.68365 12.3007 4.56305 12.6514 4.45215C12.5832 4.46861 12.5151 4.48508 12.4475 4.50203C12.1121 4.58533 11.7724 4.67638 11.4337 4.77808Z"
        fill="#C68A4F"
      />
      <path
        d="M17.9934 39.9907C19.7105 39.8968 21.3167 39.1379 22.9974 38.7703C25.0777 38.315 27.3806 38.4158 29.3472 37.762C29.2522 37.7591 29.1572 37.7552 29.0627 37.7508C28.6045 37.7286 28.1453 37.6898 27.6867 37.6516C26.3361 37.8254 24.9342 37.8918 23.6206 38.179C21.94 38.5465 20.3337 39.3054 18.6166 39.3999C17.279 39.4735 15.9212 39.1253 14.7808 38.4158C13.1122 37.3784 11.9291 35.617 10.1433 34.8029C9.38483 34.4571 8.54234 34.2963 7.83804 33.8493C7.2009 33.4454 6.72161 32.8347 6.29413 32.2094C2.60901 26.8182 2.13931 19.3585 5.11821 13.5391C5.54281 12.71 6.03266 11.9089 6.33636 11.0275C6.74032 9.85404 6.80509 8.56434 7.35779 7.4548C7.55018 7.06784 7.7915 6.72157 8.07025 6.41064C7.52043 6.85717 7.05937 7.39378 6.73457 8.04565C6.18187 9.15567 6.11758 10.4454 5.71313 11.6183C5.40944 12.4998 4.91959 13.3008 4.49499 14.1299C1.51561 19.9488 1.9853 27.4086 5.67091 32.8003C6.09839 33.4255 6.57768 34.0357 7.21482 34.4401C7.91912 34.8872 8.76208 35.0479 9.52012 35.3937C11.3063 36.2078 12.4889 37.9693 14.1576 39.0066C15.2985 39.7156 16.6558 40.0639 17.9934 39.9907Z"
        fill="#C68A4F"
      />
      <path
        d="M30.6396 32.2201C30.2793 32.2496 29.9416 32.0322 29.6916 31.7687C29.4416 31.5053 29.2488 31.19 28.9887 30.9367C28.9225 30.8718 28.8462 30.8088 28.7551 30.7962C28.6591 30.7827 28.5651 30.8277 28.4787 30.8723C28.1386 31.0491 27.8027 31.2486 27.429 31.3324C27.0553 31.4162 26.6259 31.3634 26.3562 31.0892C26.1067 30.836 26.0425 30.4413 26.1053 30.0896C26.1677 29.738 26.3394 29.417 26.5083 29.1031C26.2315 29.4949 25.9777 29.9158 25.8654 30.3836C25.7532 30.8515 25.7992 31.3774 26.0808 31.7658C26.3625 32.1542 26.908 32.3596 27.3441 32.1644C27.4539 32.1155 27.5566 32.0428 27.6756 32.0254C27.8728 31.9963 28.0633 32.129 28.1736 32.2966C28.2839 32.4637 28.3334 32.6632 28.3996 32.8526C28.5353 33.2405 28.7628 33.6159 29.1183 33.8168C29.6801 34.1341 30.4194 33.921 30.8944 33.4832C31.3694 33.0449 31.6376 32.4288 31.8803 31.8268C32.2387 30.9386 32.6196 29.9414 32.4652 28.9661C32.0981 29.9095 31.9638 32.1102 30.6396 32.2201Z"
        fill="#E2A359"
      />
      <path
        d="M14.7986 9.32178C14.8691 9.40459 14.942 9.49177 14.966 9.59783C14.9943 9.72568 14.9468 9.85693 14.8998 9.97897C14.7573 10.3504 14.6153 10.7214 14.4728 11.0929C14.409 11.2595 14.339 11.4348 14.1984 11.5433C14.0833 11.6324 13.9331 11.6668 13.8223 11.7607C13.6903 11.8731 13.6352 12.0532 13.6169 12.2266C13.5445 12.9197 14.0286 13.6296 14.6959 13.8093C14.8063 13.8389 14.9329 13.8529 15.027 13.787C15.0989 13.7367 15.1354 13.6505 15.18 13.5744C15.4151 13.1763 15.9899 13.032 16.3823 13.2727C16.2216 13.1914 16.0206 13.2587 15.8834 13.3764C15.7461 13.494 15.6531 13.6539 15.5394 13.7948C15.2616 14.1401 14.8514 14.3745 14.4148 14.436C14.208 14.4656 13.9868 14.4544 13.805 14.3503C13.6567 14.2651 13.5488 14.1261 13.4332 13.9997C13.1371 13.6766 12.7706 13.4195 12.3671 13.2519C12.2074 13.1855 12.0356 13.1294 11.9137 13.0054C11.7919 12.8814 11.7434 12.6615 11.8629 12.5351C12.0327 12.355 12.464 12.3942 12.4304 12.1477C12.4175 12.0528 12.3259 11.9932 12.2668 11.9181C12.0476 11.6377 12.3359 11.2222 12.6641 11.088C12.9923 10.9539 13.3785 10.9437 13.6515 10.7166C13.8232 10.5737 13.9259 10.3611 13.9796 10.1432C14.0693 9.78041 14.0338 9.38619 13.8731 9.0496C13.6635 8.61082 13.2398 8.28295 12.7658 8.19238C13.8314 8.39579 14.0943 8.49895 14.7972 9.32081L14.7986 9.32178Z"
        fill="#E2A359"
      />
      <path
        d="M11.9471 28.6688C12.2129 29.0843 12.6692 29.3706 13.1561 29.4267C13.2871 29.4417 13.432 29.4461 13.5299 29.5357C13.5817 29.5832 13.6124 29.6485 13.6426 29.712C14.0015 30.4767 14.3599 31.2414 14.7187 32.0066C14.227 31.4119 14.1003 30.5053 13.445 30.1028C13.2766 29.9992 13.0861 29.9396 12.898 29.8805C12.579 29.7808 12.2594 29.681 11.9404 29.5807C11.83 29.5464 11.7144 29.5081 11.6367 29.4219C11.5619 29.3386 11.535 29.2243 11.5057 29.1158C11.202 27.983 10.3816 27.0023 9.32564 26.5112C9.84859 26.6536 10.658 26.7771 11.0926 27.1166C11.5633 27.4842 11.6381 28.1864 11.9476 28.6693L11.9471 28.6688Z"
        fill="#E2A359"
      />
      <path
        d="M25.4726 21.391C25.0303 20.9808 24.7913 20.3483 24.2636 20.0592C23.8644 19.8408 23.3827 19.8674 22.9293 19.8999C22.4395 19.9347 21.9492 19.9696 21.4593 20.005L23.6408 20.2016C23.7483 20.2113 23.8601 20.2224 23.9537 20.2771C24.1033 20.3648 24.172 20.542 24.2492 20.6985C24.5112 21.2293 24.9698 21.6293 25.4165 22.0143C25.6141 22.1843 25.8214 22.3601 26.0738 22.4221C26.2772 22.4725 26.4902 22.4439 26.6989 22.4255C27.4761 22.3543 28.2375 22.4468 28.9941 22.6241C28.4304 22.2419 28.1934 22.0211 27.5073 21.9848C26.7771 21.946 26.045 21.9213 25.4726 21.3905L25.4726 21.391Z"
        fill="#E2A359"
      />
      <path
        d="M34.8846 27.9782C35.2435 27.4769 35.2694 26.8081 35.1926 26.1945C35.1158 25.5813 34.9498 24.9735 34.9748 24.3561C35.0007 23.7265 35.2238 23.1235 35.3332 22.5031C35.3365 22.4837 35.3399 22.4634 35.3433 22.4435C35.1365 22.7017 34.9038 22.937 34.5862 23.0044C34.1395 23.0993 33.6453 22.753 33.2601 22.5782C33.1018 22.5065 32.9338 22.4629 32.7602 22.4363C32.9108 22.7617 33.0284 23.1066 33.1435 23.447C33.3205 23.971 33.4985 24.4994 33.5643 25.0486C33.6833 26.0424 33.4414 27.1156 33.8881 28.0092C33.9342 28.1012 33.9893 28.1927 34.0723 28.2532C34.33 28.4407 34.698 28.2377 34.8841 27.9782L34.8846 27.9782Z"
        fill="#FFC552"
      />
      <path
        d="M22.6987 11.1568C22.4118 10.7074 22.1096 10.2667 21.8347 9.80996C21.5847 9.69857 21.3439 9.56878 21.1673 9.3305C20.45 8.36432 20.9135 6.99955 19.8911 6.11037C19.5735 5.83432 19.1815 5.66094 18.7867 5.52001C17.628 5.10641 16.3835 4.93739 15.1577 5.02747C14.8175 5.05265 14.4654 5.10157 14.1785 5.28803C13.8062 5.52969 13.5668 5.98639 13.1388 6.10069C12.9728 6.14476 12.778 6.13846 12.6566 6.26147C12.4614 6.45955 12.6509 6.82762 12.9167 6.9056C13.1825 6.98357 13.4626 6.87848 13.7275 6.79663C14.3306 6.61066 14.9691 6.54237 15.5971 6.59661C15.9651 6.62858 16.371 6.72834 16.5706 7.04217C16.7735 7.36133 16.6901 7.77734 16.609 8.14735C16.4766 8.75224 16.395 9.46175 16.8062 9.92135C17.1008 10.2512 17.5652 10.3504 17.9595 10.5471C18.636 10.8841 19.1412 11.5312 19.8542 11.7791C20.5647 12.0261 21.3482 11.8305 22.0544 11.5699C22.2972 11.4803 22.538 11.3345 22.6987 11.1573L22.6987 11.1568Z"
        fill="#FFC552"
      />
      <path
        d="M19.7349 11.0804C19.6715 10.5889 19.4283 10.1002 19.0061 9.8474C18.7125 9.67112 18.3589 9.6222 18.0173 9.63431C17.7764 9.64254 17.5322 9.6808 17.3144 9.78541C16.6355 10.1113 16.3971 10.956 15.8861 11.5129C15.6957 11.7207 15.451 11.9144 15.4102 12.1943C15.3819 12.39 15.463 12.5832 15.545 12.7629C15.7868 13.2903 16.1198 13.8569 16.6782 13.9993C17.0909 14.1049 17.5207 13.947 17.9127 13.7795C19.0973 13.2743 19.9124 12.466 19.7344 11.0804L19.7349 11.0804Z"
        fill="#683506"
      />
      <path
        d="M10.3841 19.4516C11.1839 19.5068 11.9184 19.9931 12.3862 20.6503C12.854 21.3074 13.0742 22.1191 13.126 22.9265C13.1716 23.6384 13.0392 24.454 12.4534 24.853C11.8311 25.2768 10.8486 24.9412 10.6087 24.2234C10.4839 23.8491 10.5237 23.3929 10.246 23.1144C9.9461 22.8141 9.45386 22.8931 9.04317 22.7928C8.38732 22.6325 7.92387 21.9245 7.99919 21.2474C8.10186 20.3272 9.49704 19.3901 10.3841 19.4516Z"
        fill="#683506"
      />
      <path
        d="M21.242 22.4213C20.8562 22.2561 20.3587 22.4368 20.1658 22.8121C19.9739 23.186 20.0776 23.6485 19.9456 24.048C19.8146 24.4447 19.4735 24.7246 19.2097 25.0467C18.9458 25.3687 18.7505 25.8346 18.9611 26.1949C19.1339 26.4899 19.5119 26.5892 19.8511 26.5882C20.0636 26.5872 20.2786 26.5567 20.4724 26.4681C20.7574 26.3378 20.9747 26.0918 21.1475 25.8283C21.5936 25.1479 22.3047 22.877 21.2415 22.4208L21.242 22.4213Z"
        fill="#683506"
      />
      <path
        d="M20.758 33.1398C20.3018 32.8284 19.7395 32.733 19.2031 32.6066C18.613 32.4681 18.0017 32.2642 17.6031 31.8046C17.4543 31.6327 17.3291 31.422 17.1204 31.3343C16.9985 31.2835 16.8613 31.2815 16.7313 31.3029C16.0783 31.4099 15.6192 32.086 15.5947 32.7538C15.5703 33.4212 15.8984 34.058 16.3273 34.5661C16.8748 35.2141 17.6007 35.7071 18.4014 35.9744C18.872 36.1314 19.3844 36.2113 19.8637 36.0829C21.043 35.7676 21.9695 33.967 20.758 33.1403L20.758 33.1398Z"
        fill="#683506"
      />
      <path
        d="M32.5004 25.8584C32.5057 25.4308 32.3152 24.9721 31.9328 24.7871C31.5888 24.621 31.1829 24.7077 30.8039 24.6651C30.293 24.6079 29.8381 24.3164 29.3406 24.1847C29.0542 24.1091 28.7044 24.1062 28.5135 24.3348C28.2678 24.6288 28.4458 25.0666 28.4957 25.4477C28.5859 26.1383 28.2031 26.8236 28.2832 27.5152C28.3504 28.093 28.7673 28.6146 29.3123 28.8025C29.8573 28.9904 30.5036 28.8354 30.9066 28.4199C31.1028 28.2174 31.2419 27.9632 31.4487 27.7724C32.0436 27.2227 32.4884 26.7398 32.4994 25.8584L32.5004 25.8584Z"
        fill="#683506"
      />
      <path
        d="M12.6596 20.1243C13.0693 20.7147 13.254 21.4344 13.3663 22.1468C13.4349 22.5807 13.4795 23.0224 13.4325 23.4593C13.3922 23.8336 13.2852 24.1993 13.1264 24.5397C13.0842 24.6308 13.0367 24.7335 13.0703 24.8284C13.0991 24.9093 13.1797 24.9601 13.2607 24.9867C13.5975 25.0962 13.953 24.886 14.2131 24.6434C14.4338 24.4375 14.6305 24.1939 14.7236 23.9058C14.951 23.2035 14.5259 22.48 14.3062 21.7753C14.1416 21.2474 14.0907 20.6866 13.8945 20.1699C13.6043 19.4042 12.9191 18.7392 12.1074 18.7082C11.9116 18.701 11.7144 18.7296 11.5206 18.6995C11.346 18.6729 11.1757 18.5988 10.9996 18.6128C10.8245 18.6264 10.6589 18.7649 10.6863 18.947C11.4798 18.9678 12.2091 19.4759 12.6591 20.1248L12.6596 20.1243Z"
        fill="#FFC552"
      />
      <path
        d="M17.6364 30.8492C17.4353 30.6491 17.135 30.5121 16.8697 30.6099C16.6332 30.6971 16.495 30.9383 16.3112 31.1126C16.1275 31.287 15.7955 31.3785 15.6482 31.1722C15.5992 31.1034 15.5834 31.0153 15.5877 30.9305C15.6012 30.6675 15.7854 30.4472 15.9797 30.2714C16.2623 30.0147 16.5928 29.8006 16.9623 29.7086C17.3317 29.6166 17.7433 29.6573 18.0586 29.8718C18.3431 30.0651 18.5306 30.3813 18.8214 30.5649C19.0349 30.6995 19.2868 30.7533 19.53 30.8186C19.7733 30.884 20.0223 30.9707 20.1983 31.1523C20.3744 31.3339 20.4512 31.6366 20.3101 31.8468C20.0414 32.2478 19.0891 32.1379 18.7034 31.9926C18.1934 31.8003 17.9986 31.209 17.6368 30.8482L17.6364 30.8492Z"
        fill="#FFC552"
      />
      <path
        d="M21.9698 22.1878C22.3536 22.5036 22.5599 23.0266 22.4961 23.5226C22.4678 23.7424 22.3906 23.955 22.3776 24.1769C22.3647 24.3982 22.4342 24.6466 22.6252 24.7571C22.8919 24.9115 23.2287 24.7198 23.4326 24.4873C23.9431 23.9056 24.0808 23.0179 23.7709 22.3065C23.4609 21.595 22.7197 21.0977 21.9501 21.0841C21.4809 21.0759 21.0055 21.2313 20.5482 21.1228C20.3928 21.086 20.2393 21.0182 20.08 21.0318C19.7249 21.0623 19.5234 21.5519 19.7206 21.8517C19.9874 22.2585 20.353 22.0556 20.6778 21.9258C21.0976 21.7578 21.6282 21.9055 21.9703 22.1873L21.9698 22.1878Z"
        fill="#FFC552"
      />
      <path
        d="M21.4274 23.2257C21.3568 23.0528 21.2086 22.9138 21.032 22.8561C20.9102 22.8159 20.7586 22.8217 20.6751 22.9201C20.593 23.0169 20.6098 23.1671 20.6674 23.2804C20.725 23.3937 20.8166 23.4862 20.8828 23.5947C21.1136 23.9734 20.9989 24.4568 20.9601 24.8994C20.9557 24.9464 20.9553 25.0001 20.9884 25.0331C21.0229 25.0679 21.0829 25.0636 21.1237 25.0369C21.1645 25.0098 21.1904 24.9658 21.2134 24.9222C21.4336 24.5066 21.615 23.6867 21.4269 23.2257L21.4274 23.2257Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M19.1581 10.5134C18.9695 10.2644 18.6668 10.1104 18.3568 10.0925C18.2734 10.0876 18.188 10.0925 18.1078 10.1182C17.8022 10.2174 17.6693 10.5778 17.417 10.7783C17.3205 10.8548 17.2059 10.9081 17.1171 10.9938C17.0283 11.08 16.9698 11.217 17.024 11.3284C17.0782 11.4398 17.2255 11.4771 17.344 11.4442C17.4625 11.4112 17.559 11.3265 17.6506 11.2437C17.747 11.157 17.844 11.0698 17.9404 10.9831C18.0354 10.8979 18.1352 10.8097 18.259 10.7797C18.5128 10.7182 18.7838 10.9851 18.7291 11.2422C18.6716 11.5134 18.3424 11.6883 18.352 11.9653C18.3588 12.1634 18.5627 12.3169 18.7584 12.3072C18.9546 12.297 19.1269 12.1551 19.2199 11.9808C19.433 11.5822 19.4339 10.8766 19.1581 10.5124L19.1581 10.5134Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M11.6155 20.4142C11.4332 20.2128 11.2284 20.0239 10.9794 19.9178C10.6776 19.7895 10.3221 19.7958 10.0251 19.9353C9.90228 19.9929 9.78618 20.0752 9.71661 20.1924C9.64704 20.3096 9.63169 20.4661 9.70318 20.5823C9.83127 20.7901 10.1278 20.7688 10.3696 20.7784C11.0628 20.8061 11.7033 21.3509 11.8473 22.0362C11.9332 22.444 11.8574 22.8668 11.8655 23.2837C11.8693 23.4789 11.9178 23.7104 12.0972 23.7821C12.2613 23.8475 12.4518 23.7308 12.5319 23.5724C12.612 23.414 12.6106 23.2276 12.6072 23.0498C12.5981 22.5679 12.6509 22.1079 12.4297 21.6749C12.2018 21.2293 11.972 20.7741 11.6155 20.4142Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M20.8691 34.2011C20.7832 33.9444 20.5898 33.7366 20.3711 33.5792C20.2516 33.4935 20.1216 33.4194 19.9791 33.3841C19.7838 33.3351 19.579 33.3618 19.3779 33.3569C18.9917 33.3477 18.6089 33.2189 18.2941 32.9923C18.1425 32.8833 17.9463 32.7467 17.7913 32.8504C17.6867 32.9201 17.6671 33.0751 17.7146 33.1928C17.7621 33.31 17.8599 33.3981 17.9564 33.4795C18.1704 33.6601 18.4002 33.8345 18.6688 33.91C18.8612 33.9642 19.0656 33.9652 19.2561 34.0262C19.4465 34.0873 19.6327 34.235 19.6476 34.436C19.6672 34.6994 19.3923 34.9091 19.3928 35.1731C19.3928 35.4748 19.7723 35.6545 20.0592 35.5692C20.5625 35.4196 21.0442 34.727 20.8691 34.2016L20.8691 34.2011Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M31.4259 25.1565C31.3592 25.1565 31.2882 25.1701 31.2393 25.2161C31.2105 25.2432 31.1918 25.279 31.1798 25.3168C31.1323 25.4674 31.2028 25.6471 31.34 25.7241C31.3765 25.7444 31.4168 25.7585 31.4485 25.7851C31.5075 25.835 31.5252 25.9202 31.5204 25.9982C31.5089 26.1944 31.3688 26.3765 31.1836 26.437C31.1011 26.4636 31.0133 26.468 30.9284 26.4849C30.7154 26.528 30.5086 26.6733 30.4563 26.8864C30.4194 27.037 30.4683 27.2061 30.5796 27.3126C30.6914 27.4192 30.8612 27.4589 31.008 27.4133C31.2038 27.3523 31.3285 27.1654 31.4744 27.0201C31.6212 26.8733 31.8011 26.7605 31.9311 26.5987C32.2986 26.1425 32.124 25.157 31.425 25.1575L31.4259 25.1565Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M19.1063 36.7546C18.8482 36.7643 18.5858 36.7348 18.3454 36.6394C17.5533 36.3255 17.0764 35.3453 16.2292 35.2659C16.4019 35.5274 16.6749 35.7042 16.8749 35.9449C17.1124 36.2311 17.2362 36.5933 17.3974 36.9295C17.5586 37.2656 17.7831 37.6007 18.1262 37.7397C18.3972 37.8491 18.7081 37.8186 18.983 37.7179C19.2575 37.6172 19.5031 37.451 19.7454 37.2864C20.1503 37.0113 20.5557 36.7362 20.9607 36.4611C21.0489 36.4011 21.1487 36.3105 21.1185 36.2078C21.0226 35.8853 20.4799 36.3047 20.3466 36.3875C19.9781 36.6151 19.5362 36.7391 19.1063 36.7551L19.1063 36.7546Z"
        fill="#E2A359"
      />
      <path
        d="M8.58902 13.4506C8.70656 13.8622 8.71088 14.3262 8.97044 14.6647C9.16714 14.9209 9.4766 15.0575 9.77501 15.1771C10.4088 15.4309 11.0541 15.6541 11.7085 15.8464C10.7869 15.6876 9.87721 15.4595 8.98963 15.164C8.85337 15.1185 8.71088 15.0672 8.61732 14.9577C8.54152 14.8686 8.50698 14.7524 8.47435 14.6395C8.37984 14.3107 8.28532 13.9818 8.19081 13.653C8.1697 13.5789 8.14523 13.5004 8.08622 13.4511C7.99266 13.3736 7.85545 13.4012 7.73886 13.4336C7.26053 13.5678 6.78796 13.7223 6.32258 13.8956C6.39646 13.4239 6.66993 12.9881 7.06047 12.7193C7.64771 12.3154 8.41342 12.836 8.58902 13.4501L8.58902 13.4506Z"
        fill="#E2A359"
      />
      <path
        d="M19.8999 8.64918C19.7588 8.11645 19.7257 7.54933 19.494 7.05001C19.1932 6.40202 18.5791 5.93854 17.9088 5.70656C17.2381 5.47458 16.5127 5.45327 15.806 5.5109C15.7758 5.78792 15.9739 6.04702 16.2147 6.18214C16.4556 6.31775 16.7358 6.35891 17.0054 6.41703C17.4612 6.51583 17.9242 6.68194 18.2423 7.0258C18.5599 7.36965 18.6851 7.93048 18.4227 8.31937C18.3128 8.4821 18.1372 8.62739 18.1444 8.82401C18.1535 9.08409 18.4591 9.20468 18.7028 9.28895C19.1802 9.45458 19.6216 9.72579 19.9872 10.0774C20.2506 10.3312 20.7438 10.5956 20.5711 10.0357C20.4243 9.56064 20.0356 9.16061 19.8999 8.64821L19.8999 8.64918Z"
        fill="white"
        opacity="0.43"
      />
      <path
        d="M27.2383 33.2591C27.0896 33.216 26.9279 33.2761 26.8031 33.369C26.6784 33.462 26.5796 33.585 26.4659 33.6911C26.2615 33.8819 26.0072 34.0175 25.7361 34.0805C25.6622 34.0974 25.5744 34.1197 25.5485 34.1919C25.5289 34.2461 25.5552 34.3062 25.585 34.3551C25.8177 34.7406 26.333 34.9237 26.7532 34.7706C26.8909 34.7202 27.0162 34.6398 27.1332 34.5507C27.2757 34.4423 27.4115 34.3159 27.4897 34.1536C27.6015 33.9216 27.5338 33.3448 27.2378 33.2591L27.2383 33.2591Z"
        fill="#E2A359"
      />
      <path
        d="M28.811 35.1535C28.7755 34.9385 28.646 34.6968 28.4301 34.6901C28.2842 34.6857 28.1547 34.799 28.0938 34.9332C28.0333 35.0673 28.0276 35.2194 28.0242 35.3671C28.0199 35.5657 28.0544 35.8166 28.243 35.8737C28.3394 35.9028 28.445 35.8645 28.528 35.8069C28.7587 35.6461 28.8633 35.3226 28.7703 35.0552L28.811 35.1535Z"
        fill="#E2A359"
      />
      <path
        d="M12.8039 8.89167C12.6129 8.82096 12.3966 8.79578 12.208 8.87181C12.0195 8.94785 11.8693 9.14302 11.8899 9.34691C11.9048 9.49753 12.0041 9.62587 12.1116 9.73193C12.3136 9.9305 12.5745 10.0879 12.8567 10.0908C13.7888 10.101 13.4017 9.11251 12.8044 8.89167L12.8039 8.89167Z"
        fill="#E2A359"
      />
      <path
        d="M10.3374 9.15234C10.336 8.86224 10.1983 8.57553 9.97373 8.39489C9.93679 8.36535 9.89649 8.33774 9.84995 8.33048C9.79574 8.32176 9.74105 8.34113 9.69355 8.36922C9.47765 8.49611 9.39849 8.76877 9.36155 9.01819C9.32748 9.24726 9.32508 9.51266 9.4906 9.67345C9.6614 9.83956 9.96462 9.81002 10.1311 9.64003C10.2942 9.47392 10.3307 9.19593 10.216 8.99252L10.3374 9.15282L10.3374 9.15234Z"
        fill="#E2A359"
      />
      <path
        d="M24.5144 36.501C24.2476 36.2269 23.8921 36.0535 23.6244 35.7804C23.0467 35.1905 22.9868 34.2718 23.0151 33.4431C23.0434 32.614 23.112 31.7297 22.7027 31.0105C22.9019 32.0503 22.8467 33.139 22.5435 34.1531C22.4566 34.4442 22.3487 34.7503 22.4259 35.0438C22.589 35.6646 23.4958 35.9388 23.4925 36.5809C23.4915 36.827 23.3461 37.0454 23.207 37.2469C22.9532 37.614 22.6994 37.9816 22.4451 38.3487C23.1278 37.9961 25.7699 37.7912 24.5144 36.501Z"
        fill="#E2A359"
      />
      <path
        d="M26.2693 6.39612C26.0443 6.28521 25.7924 6.24308 25.5429 6.21935C25.2109 6.18738 24.853 6.19126 24.5738 6.37578C24.2945 6.56029 24.1525 6.986 24.3646 7.24607C24.5056 7.41896 24.7431 7.46449 24.9557 7.52793C25.3443 7.64368 25.7377 7.89455 25.837 8.29168C25.8687 8.4176 25.8701 8.55562 25.9373 8.66653C26.0807 8.9019 26.4453 8.8801 26.666 8.71593C27.4274 8.14978 27.0172 6.76516 26.2683 6.3966L26.2693 6.39612Z"
        fill="#FFC552"
      />
      <path
        d="M21.1415 13.5139C21.0839 13.9769 21.0556 14.5106 20.6877 14.7929C20.1431 15.2104 19.281 14.7503 18.7043 15.1208C18.3262 15.3634 18.2216 15.8695 18.2024 16.3209C18.1703 17.0754 18.3507 17.9443 19.0037 18.3128C19.7914 18.7574 20.7577 18.2349 21.6606 18.2373C22.1908 18.2387 22.718 18.4344 23.123 18.7797C23.2352 18.8756 23.3609 18.9904 23.5068 18.97C23.7424 18.9371 23.7846 18.6107 23.7414 18.3748C23.6334 17.7854 23.3744 17.2246 22.9958 16.7631C22.8428 16.5761 22.6657 16.3969 22.5919 16.1659C22.2896 15.2152 23.2943 14.1561 22.8932 13.2059C22.4134 12.0692 21.2806 12.4019 21.141 13.5139L21.1415 13.5139Z"
        fill="#FFC552"
      />
    </g>
  </svg>
</template>
