<i18n>
ru:
  close: Понятно
  title: 'Мы используем cookies, сервисы Google Analytics и Яндекс.Метрика'
  message: 'Продолжая пользоваться сайтом, Вы принимаете '
  messageLink: условия обработки персональных данных
ua:
  close: Зрозуміло
  title: 'Ми використовуємо cookies, сервіси Google Analytics і Яндекс.Метрика'
  message: 'Продовжуючи користуватися сайтом, Ви приймаєте '
  messageLink: умови обробки персональних даних
us:
  close: Got it
  title: 'We use cookies, Google Analytics services'
  message: 'By continuing to browse the site, you accept '
  messageLink: our personal data policy
</i18n>

<template>
  <transition
    appear
    name="fade"
  >
    <div
      v-if="!closedCookieNotification"
      :id="uid"
      class="v-cookie-notification"
    >
      <div
        class="v-cookie-notification-content"
        v-once
        data-test-id="cookie-notification-text"
      >
        <icon-cookie />
        <span
          class="v-cookie-notification-content-title v-d-flex v-align-items-end"
          v-html="translate('cookieNotificator.title')"
        />
        <div class="v-cookie-notification-content-subtitle v-mb-xs">
          <span v-html="translate('cookieNotificator.message')" />

          <arora-nuxt-link
            class-name="v-link v-link-color"
            open-in-new-tab
            :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
            :label="translate('cookieNotificator.messageLink')"
          />
        </div>
        <div class="v-cookie-notification-content-button v-d-flex v-align-items-center">
          <arora-button
            :label="translate('cookieNotificator.close')"
            data-test-id="cookie-notification-close"
            @click="close"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

const closedCookieNotification = useCookie('closedCookieNotification')

const uid = useId()
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()

function close(): void {
  if (document.getElementById(uid))
    gsap.to(`#${uid}`, {
      duration: 0.5,
      ease: 'sine.inOut',
      height: 0,
      onComplete: () => {
        closedCookieNotification.value = 'true'
      }
    })
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-cookie-notification {
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  border: 1px solid variables.$BorderColorLight;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$FloatShadow;

  position: fixed;
  bottom: 10px;
  z-index: 9998;
  padding: 5px;
  width: 95%;
  max-width: calc(variables.$ContainerWidth - 30px);
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden; //for animation

  .v-link-color {
    text-decoration: underline;
    margin-left: 3px;
  }

  .v-cookie-notification-content {
    padding: 12px 28px 16px;

    display: grid;
    grid-template-columns: 55px auto 180px;
    grid-template-rows: 1fr 1fr;
    gap: 0 15px;
    grid-template-areas:
      'icon title button'
      'icon subtitle button';

    @include mixins.sm {
      grid-template-columns: 45px auto 180px;
      grid-template-rows: 1fr auto auto;
      gap: 5px 5px;
      grid-template-areas:
        'icon title title'
        'subtitle subtitle subtitle'
        '. . button';
    }

    svg {
      grid-area: icon;
      margin: auto 0;
    }

    .v-cookie-notification-content-title {
      font-size: 1.25rem;
      font-weight: 500;

      grid-area: title;
    }

    .v-cookie-notification-content-subtitle {
      display: block;
      grid-area: subtitle;
    }

    .v-cookie-notification-content-button {
      grid-area: button;

      justify-content: flex-end;

      .v-btn {
        width: 100%;

        @include mixins.sm {
          width: auto;
        }
      }
    }
  }
}
</style>
